import React from "react"
import Container from "../components/container"
import Image from "../components/image"
import { Helmet } from "react-helmet"



export default function Home() {
  return (
    <Container>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Huize Poort</title>
        </Helmet>
        <h1>Hey ouwe dibbes!</h1>
        <p>Trek een rietbak!</p>
        <p>Liefs huize pikkie poort </p>
      </div>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
    </Container>
  )
}
